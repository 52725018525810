<template>
	<PaddingContainer
		id="carrossel-de-imoveis"
		component="section"
		:use-fallback-container="false"
		:data-variant="variant"
		:class="{
			'property-carousel-listing-wrapper': !isKollerVariant,
			'property-carousel-listing-koller': isKollerVariant
		}"
		:padding="padding"
	>
		<h2 v-if="title && !isKollerVariant" class="property-carousel-heading">
			{{ title }}
		</h2>

		<PaddingContainer
			v-if="isKollerVariant"
			class="carousel-content-wrapper"
			:padding="textSubsectionPadding"
			:use-fallback-container="false"
			component="div"
		>
			<div class="carousel-text-content">
				<NuxtImg
					v-if="svgHeading"
					:src="svgHeading.url"
					:alt="svgHeading.text"
					:title="svgHeading.text"
					sizes="700px md:1000px lg:1440px"
					:aria-label="svgHeading.text"
					class="svg-heading"
				/>

				<h2 v-if="title" class="property-carousel-heading default-font">
					{{ title }}
				</h2>
				<span v-if="subtitle" class="property-carousel-subheading default-font">
					{{ subtitle }}
				</span>
			</div>
			<div class="carousel-buttons">
				<ButtonBlock
					v-if="showRedirectionButton"
					text="Ver mais imóveis"
					:is-outlined="false"
					:url="{ name: 'PropertiesListing' }"
					class="redirection-button"
				/>

				<div class="carousel-navigation">
					<button
						aria-label="Ir para a imagem anterior"
						@click="carousel.goToPreviousImage"
					>
						<ChevronLeft />
					</button>
					<button
						aria-label="Ir para a próxima imagem"
						@click="carousel.goToNextImage"
					>
						<ChevronRight />
					</button>
				</div>
			</div>
		</PaddingContainer>

		<LoadingIcon v-if="isFetchingProperties" class="loading" />

		<div
			v-else
			class="carousel-listing"
			:class="{
				container: !padding && !isMinisiteVariant
			}"
		>
			<div v-if="properties.length">
				<div
					:key="carouselId"
					ref="carouselContainer"
					:class="carouselContainerClass"
					class="carousel-container"
				>
					<div
						:class="[
							carouselWrapperClass,
							{ 'carousel-wrapper': !isMinisiteVariant }
						]"
					>
						<PropertyCard
							v-for="(property, index) in properties"
							:key="property.id"
							:class="carouselItemClass"
							:variant="cardVariant"
							:property="property"
							:source="source"
							:use-cta-directive="useCtaDirective"
							:tag-text-color="tagTextColor"
							:tag-background-color="tagBackgroundColor"
							:optimized-width="optimizedImageSettings?.width"
							:optimized-quality="optimizedImageSettings?.quality"
							:should-show-get-in-touch-button="shouldShowGetInTouchButton"
							@send-get-in-touch-message="
								() => emit('send-get-in-touch-message', property)
							"
							@open-schedule-property-visit-modal="
								() => emit('open-schedule-property-visit-modal', property)
							"
							@open-gallery="() => emit('open-gallery', property)"
							@view-property="
								(property, trigger) =>
									emit('view-property', property, trigger, index)
							"
						/>
					</div>
				</div>

				<div class="carousel-buttons" :class="`${variant}-variant`">
					<div class="carousel-navigation">
						<button
							aria-label="Ir para a imagem anterior"
							@click="carousel.goToPreviousImage"
						>
							<ChevronLeft />
						</button>
						<button
							aria-label="Ir para a próxima imagem"
							@click="carousel.goToNextImage"
						>
							<ChevronRight />
						</button>
					</div>

					<div
						v-if="isFullsizeVariant || isMinisiteVariant"
						class="carousel-direct-item-navigators"
					>
						<span
							v-for="(property, index) in properties"
							:key="property.id"
							class="carousel-direct-item-navigator"
							:aria-label="`Ir para a ${index + 1}ª imagem`"
							:class="{ active: index === carousel.currentIndex }"
							@click="carousel.goTo(index)"
						>
						</span>
					</div>
					<ButtonBlock
						v-if="shouldDisplayViewMoreButton"
						text="Ver mais imóveis"
						:is-outlined="!isKollerVariant"
						:url="{ name: 'PropertiesListing' }"
						class="redirection-button"
					/>
				</div>
			</div>
			<ErrorMessage
				v-else
				title="Nenhum imóvel encontrado"
				:description="errorMessage"
			/>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type {
	BlockableRouteNames,
	WebsiteStyles
} from '@SHARED/core/entities/WebsiteConfig';
import type {
	PropertyCarouselSection,
	PropertyCarouselVariant
} from '@SHARED/core/entities/sections/PropertyCarouselSection';
import type { Property } from '@SHARED/core/entities/Property';
import type { CssSize } from '@SHARED/utils/helperTypes';
import type { PropertyCardTrigger } from '@SHARED/components/molecules/PropertyCard.vue';
import type { PropertySource } from '@SHARED/core/entities/sections/PropertyListingSection';

import { getCSSColorVar, getPadding } from '@SHARED/utils/style';

import { useCarousel } from '@SHARED/composables/useCarousel';
import { useId } from '@SHARED/composables/useId';

import { PROPERTIES_REPOSITORY } from '@SHARED/utils/vueProvidersSymbols';

import PropertyCard from '@SHARED/components/molecules/PropertyCard.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import ErrorMessage from '@SHARED/components/molecules/ErrorMessage.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';

import LoadingIcon from '~icons/mdi/loading';
import ChevronLeft from '~icons/mdi/chevron-left';
import ChevronRight from '~icons/mdi/chevron-right';

defineOptions({ name: 'PropertyCarouselSection' });

type PropertyCarouselSectionProps = Omit<
	PropertyCarouselSection['config'],
	'title'
> & {
	pageKey?: string | null;
	properties?: Property[] | null;
	title?: string | null;
	showRedirectionButton?: boolean;
	shouldShowGetInTouchButton?: boolean;
	useCtaDirective?: boolean;
	source?: PropertySource;
};

type Emits = {
	(e: 'open-gallery', property: Property): void;
	(
		e: 'view-property',
		property: Property,
		trigger: PropertyCardTrigger,
		index: number
	): void;
	(e: 'open-schedule-property-visit-modal', property: Property): void;
	(e: 'send-get-in-touch-message', property: Property): void;
};

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<PropertyCarouselSectionProps>(), {
	title: null,
	variant: 'default',
	padding: null,
	properties: null,
	cardVariant: 'default',
	propertiesLimit: 20,
	source: 'base',
	pageKey: null,
	propertySlug: null,
	useCtaDirective: false,
	showRedirectionButton: false,
	shouldShowGetInTouchButton: false
});

const route = useRoute();

const styles = useState<WebsiteStyles>('styles');

const domain = useState<string>('domain');

const blockedRoutes = useState<BlockableRouteNames[]>('blockedRoutes');

const isPropertiesListingPageEnabled = computed<boolean>(
	() => !blockedRoutes.value.includes('PropertiesListing')
);

const isKollerVariant = computed<boolean>(() => props.variant === 'koller');

const isFullsizeVariant = computed<boolean>(() => props.variant === 'fullsize');

const isMinisiteVariant = computed<boolean>(() => props.variant === 'minisite');

const shouldDisplayViewMoreButton = computed<boolean>(
	() =>
		props.showRedirectionButton &&
		isPropertiesListingPageEnabled.value &&
		(isFullsizeVariant.value || isKollerVariant.value)
);

const defaultCarouselConfig = {
	spaceBetween: 10,
	breakpoints: {
		768: { slidesPerView: 2 },
		1440: { slidesPerView: 3 }
	}
};

const kollerCarouselConfig = {
	spaceBetween: 24,
	slidesPerView: 1.15,
	breakpoints: {
		1024: { slidesPerView: 1.25 }
	}
};

const minisiteCarouselConfig = {
	spaceBetween: 0,
	slidesPerView: 1,
	breakpoints: {
		1024: { slidesPerView: 2, spaceBetween: 32 }
	}
};

const availableConfigs: Record<PropertyCarouselVariant, UseCarouselOptions> = {
	koller: kollerCarouselConfig,
	fullsize: { spaceBetween: 0, slidesPerView: 1 },
	default: defaultCarouselConfig,
	minisite: minisiteCarouselConfig
};

const carouselConfig = computed(() => availableConfigs[props.variant]);

const {
	carouselContainer,
	carousel,
	carouselContainerClass,
	carouselWrapperClass,
	carouselItemClass
} = useCarousel({
	...carouselConfig.value,
	shouldWatchCarouselContainerElement: true
});

const tagBackgroundColor = computed<string>(() =>
	getCSSColorVar(
		props?.cardTagBackgroundColor || styles.value.appearance.background
	)
);

const tagTextColor = computed<string>(() =>
	getCSSColorVar(props?.cardTagTextColor || styles.value.appearance.text)
);

const mobileTextSubsectionRightPadding = computed<CssSize>(() =>
	getPadding('right', props.textSubsectionPadding?.mobile)
);

const desktopTextSubsectionRightPadding = computed<CssSize>(() =>
	getPadding('right', props.textSubsectionPadding?.desktop)
);

const mobileSvgHeadingWidth = computed(
	() => props.svgHeading?.width.mobile || 'auto'
);

const desktopSvgHeadingWidth = computed(
	() => props.svgHeading?.width.desktop || 'auto'
);

const carouselButtonsFlexDirection = computed<string>(() =>
	props.showRedirectionButton ? 'row-reverse' : 'row'
);

const propertiesRepository = inject(PROPERTIES_REPOSITORY)!;

const errorMessage = ref<string | null>(null);

const { data: properties, pending: isFetchingProperties } = useAsyncData<
	Property[]
>(
	`${domain.value}:properties:${props.pageKey || route.path}`,
	async () => {
		try {
			if (props.properties) return props.properties;

			const [propertiesResponse, propertiesRequestError] =
				await propertiesRepository.getPropertiesSharedAtCompanyWebsite({
					domain: domain.value,
					maxQuantity: props.propertiesLimit,
					filters: {
						highlightedOnly: true
					},
					offset: 0
				});

			if (propertiesRequestError || !propertiesResponse) {
				const error = propertiesRequestError?.originalErrorObject as any;

				// <!-- TODO: improve error handling -->
				// <!-- TODO: adicionar mensagens de erro para filtros -->

				console.error(propertiesRequestError);

				if (error?.response.status === 404) {
					errorMessage.value =
						'No momento, não temos nenhum imóvel disponível.';
				}

				return [];
			}

			return propertiesResponse.properties;
		} catch {
			return [];
		}
	},
	{ default: () => [] }
);

function getPropertiesCommercialIdsString(properties: Property[]): string {
	return properties.map(property => property.commercialId).join('-');
}

const carouselId = ref(
	useId('carousel', getPropertiesCommercialIdsString(props.properties || []))
);

type OptimizedSettings = {
	width: {
		sm: number;
		md: number;
		lg: number;
	};
	quality: number;
};

const isKollerOrFullsizeVariant = computed<boolean>(
	() => isKollerVariant.value || isFullsizeVariant.value
);

const optimizedImageSettings = computed<OptimizedSettings | null>(() => {
	if (!properties.value?.length) return null;

	if (!isKollerOrFullsizeVariant.value) {
		return { width: { sm: 500, md: 700, lg: 1000 }, quality: 80 };
	}

	if (properties.value.length <= 3) {
		return { width: { sm: 1000, md: 1440, lg: 1920 }, quality: 100 };
	}

	return { width: { sm: 700, md: 1000, lg: 1440 }, quality: 90 };
});

watch(properties, newValue => {
	carouselId.value = useId(
		'carousel',
		getPropertiesCommercialIdsString(newValue)
	);
});
</script>

<style lang="scss" scoped>
.property-carousel-listing-wrapper {
	background-color: var(--white);
	padding-top: 3rem;
	padding-bottom: 3rem;
	display: flex;
	flex-direction: column;

	&[data-variant='minisite'] {
		padding-top: 0;
		padding-bottom: 0;
	}

	.property-carousel-heading {
		font-size: 1.875rem;
		width: 100%;
		text-align: center;
		font-family: var(--heading-font);

		margin-bottom: 1.5rem;

		@include screen-up(md) {
			font-size: 2.25rem;
		}
	}
}

.property-carousel-listing-koller {
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: 2rem;

	@include screen-up(lg) {
		flex-direction: row;
	}

	.carousel-content-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include screen-up(lg) {
			gap: 2rem;
			width: 40%;
			min-width: 40%;
		}

		.carousel-text-content {
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			.property-carousel-heading {
				font-weight: 400;
				line-height: 100%;
				font-size: 2rem;

				@include screen-up(lg) {
					line-height: 125%;
					font-size: 3.5rem;
				}
			}

			.property-carousel-subheading {
				font-weight: 400;
				line-height: 150%;
				font-size: 1rem;

				@include screen-up(lg) {
					line-height: 125%;
					font-size: 1.5rem;
				}
			}
		}

		.carousel-buttons {
			display: none;

			@include screen-up(lg) {
				flex-direction: row;
				display: flex;
				align-items: center;
				flex-direction: v-bind(carouselButtonsFlexDirection);
				justify-content: space-between;
				gap: 1rem;
			}
		}
	}

	.carousel-listing {
		width: 100%;

		@include screen-up(lg) {
			width: 60%;
		}
	}
}

.carousel-listing {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
}

.carousel-wrapper {
	width: 100%;
	padding: 1rem 0;
}

.carousel-container {
	height: 100%;
}

.carousel-item {
	display: flex !important;
	height: 100%;
	width: 100vw;
	align-items: center;
	justify-content: center;
}

.carousel-image {
	height: 100%;
	width: 300px;
	object-fit: cover;
}

.carousel-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	&.fullsize-variant {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	&.koller-variant {
		margin-top: 1rem;
		padding-right: v-bind(mobileTextSubsectionRightPadding);

		@include screen-up(lg) {
			padding-right: v-bind(desktopTextSubsectionRightPadding);
			display: none;
		}
	}

	.carousel-direct-item-navigators {
		display: none;
		gap: 0.375rem;

		@include screen-up(md) {
			display: flex;
		}

		.carousel-direct-item-navigator {
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 100px;
			background-color: #e5e5e5;
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			&.active {
				background-color: var(--black);
				width: 1rem;
			}
		}
	}
}

.carousel-buttons.minisite-variant {
	justify-content: flex-end;
	position: relative;
	margin-top: 1.5rem;

	@include screen-up(lg) {
		margin-top: 1.25rem;
	}

	.carousel-direct-item-navigators {
		display: flex;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%);
	}
}

.redirection-button {
	font-weight: 600;
	text-wrap: nowrap;
}

.carousel-navigation {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	button {
		border: 2px solid var(--black);
		border-radius: 5rem;
		padding: 0.25rem;
		color: var(--black);
		transition: all 0.2s ease-in-out;
		background-color: transparent;

		&:hover {
			color: var(--white);
			background-color: var(--black);
		}

		@include screen-up(sm) {
			padding: 0.5rem;
		}
	}

	svg {
		width: 2rem;
		height: 2rem;

		@include screen-up(lg) {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.svg-heading {
	width: v-bind(mobileSvgHeadingWidth);

	@include screen-up(lg) {
		width: v-bind(desktopSvgHeadingWidth);
	}
}
</style>
